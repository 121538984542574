import {FC} from 'react';
import './Answers.css';
import form from './images/form.svg';
import line from './images/line.svg';

type Props = {
  answerTitle: string;
  description: string;
  link1: string;
  link1Title: string;
  link1Text: string;
  link2: string;
  link2Title: string;
  link2Text: string;
};

const Answers: FC<Props> = (props) => {
  const {
    answerTitle,
    description,
        link2,
    link2Text,
    } = props;

  return (
    <div className="answer-content">
      <div className="answer-window">
        <div className="answer">
          <p>あなたへのおすすめは</p>
          <h2 className="answer-title">{answerTitle}</h2>
          <p className="answer-description">{description}</p>
        </div>
      </div>
      <div className="answer-links">
        <a className="answer-link answer-link1" href={link1}>
          <p>{link1Title}</p>
          <div>
            <img src={line} alt="line" />
            <p>{link1Text}</p>
          </div>
        </a>
        <a className="answer-link answer-link2" href={link2}>
          <p>{link2Title}</p>

          <div>
            <img src={form} alt="form" />
            <p>{link2Text}</p>
          </div>
        </a>
      </div>
    </div>
  );
};
export default Answers;
