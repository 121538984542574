import {FC} from 'react';
import QandA from 'QandA';
import './App.css';
import './reset.css';

const App: FC = () => (
  <div className="main">
    <div className="main-content">
      <QandA />
    </div>
  </div>
);

export default App;
