import {FC} from 'react';
import './Questions.css';

type Props = {
  question: string;
};

const Questions: FC<Props> = (props) => {
  const {question} = props;

  return <p className="question">{question}</p>;
};
export default Questions;
