/* eslint-disable import/prefer-default-export */
export const questionDatas = [
  {
    id: 0,
    isQuestion: true,
    question:
      '申請したい補助金がある。もしくは補助金が使えるなら申請してみたいですか？',
    yesWord: 'はい',
    noWord: 'いいえ',
  },
  {
    id: 1,
    isQuestion: true,
    question:
      '補助金を使いたいのは自分の事業についてでしょうか？\nそれともお客様やどなたかにおすすめしたいものでしょうか？',
    yesWord: 'お客様や\n第三者向け',
    noWord: '自社の事業\nで行う',
  },
  {
    id: 2,
    isQuestion: true,
    question: '社内で補助金の申請書類を作成し、\n申請したことがある	',
    yesWord: 'はい',
    noWord: 'いいえ',
  },
  {
    id: 3,
    isQuestion: true,
    question: '直近の売上が5000万円以上ある',
    yesWord: 'はい',
    noWord: 'いいえ',
  },
  {
    id: 4,
    isQuestion: true,
    question: '社会保険を支払っている正社員が\n5名以上である',
    yesWord: 'はい',
    noWord: 'いいえ',
  },
  {
    id: 5,
    isQuestion: true,
    question: '社会保険を支払っている正社員が\n5名以上である',
    yesWord: 'はい',
    noWord: 'いいえ',
  },
  {
    id: 6,
    isQuestion: true,
    question: '年間売上が240万円以上ある',
    yesWord: 'はい',
    noWord: 'いいえ',
  },
];

export const answerDatas = [
  {
    id: 0,
    isQuestion: false,
    description:
      'プレミアムプランがおススメ\nお得な業務提携の話をご提案します',
    answerTitle: 'プレミアムプランがおススメ',
  ,
    link2: 'https://nakatayuto.top/A',
    link2Title: 'お申し込みはこちらへ',
    link2Text: '顧問先申し込みフォーム',
  },
  {
    id: 1,
    isQuestion: false,
    description:
      '売りたい商品やお客様のご希望に沿った補助金をリサーチし、\nご提案＆申請サポートを行います。\nまずは顧問プランの体験をお勧めします',
    answerTitle: '補助金リサーチプランがおススメ',
    link1: 'https://onl.sc/zwa38S3',
    link1Title: 'ご相談はこちらへ',
    link1Text: '株式会社ビジプラ公式LINE',
    link2: 'https://nakatayuto.top/B',
    link2Title: 'お申し込みはこちらへ',
    link2Text: '補助金リサーチ顧問プラン申し込みフォーム',
  },
  {
    id: 2,
    isQuestion: false,
    description:
      'ものづくり補助金や事業再構築補助金の申請をおおススメします。\nビジネスプランや投資内容が決まっている方はもちろんそうでない方も丁寧にヒアリングしていきますので、\nお気軽にお問い合わせください。',
    answerTitle:
      'ものづくり補助金・事業再構築補助金に\nチャレンジできる？!プランがおススメ',
    link1: 'https://onl.sc/zwa38S3',
    link1Title: 'ご相談はこちらへ',
    link1Text: '株式会社ビジプラ公式LINE',
    link2: 'https://nakatayuto.top/C',
    link2Title: 'お申し込みはこちらへ',
    link2Text: '補助金申し込みフォーム',
  },
  {
    id: 3,
    isQuestion: false,
    description:
      'ニーズに合わせた補助金、助成金リサーチのプランをおすすめします。\nアナタの補助金顧問として、1か月に1回のリサーチ申請した補助金が不採択だった場合ご返金をお約束します。',
    answerTitle: '使えるものがきっとある！\nあなたのための顧問プランがおススメ',
    link1: 'https://onl.sc/zwa38S3',
    link1Title: 'ご相談はこちらへ',
    link1Text: '株式会社ビジプラ公式LINE',
    link2: 'https://nakatayuto.top/D',
    link2Title: 'お申し込みはこちらへ',
    link2Text: 'エンドユーザー向け顧問先申し込みフォーム',
  },
  {
    id: 4,
    isQuestion: false,
    description:
      '持続化補助金の申請からスタートしましょう！\n採択率80％のビジプラが貴方の事業のヒアリングから丁寧にサポートいたします。',
    answerTitle: '持続化補助金からスタートしましょう!',
    link1: 'https://onl.sc/zwa38S3',
    link1Title: 'ご相談はこちらへ',
    link1Text: '株式会社ビジプラ公式LINE',
    link2: 'https://nakatayuto.top/E',
    link2Title: 'お申し込みはこちらへ',
    link2Text: '持続化補助金申し込みフォーム',
  },
  {
    id: 5,
    isQuestion: false,
    description:
      '税金還付のスキームをご紹介します!\nぜひ公式LINEのお友達登録とアポイントの調整をお待ちしてます',
    answerTitle: '税金還付がおすすめ',
    link1: 'https://onl.sc/zwa38S3',
    link1Title: 'ご相談はこちらへ',
    link1Text: '株式会社ビジプラ公式LINE',
    link2: 'https://nakatayuto.top/F',
    link2Title: 'お申し込みはこちらへ',
    link2Text: '中田雄斗補助金のプロ公式LINEでアポ調整',
  },
  {
    id: 6,
    isQuestion: false,
    description:
      '補助金の情報やお得な情報をご案内します！\nぜひ公式LINEのお友達登録をお願いします',
    answerTitle: '補助金のプロ中田雄斗のセミナーへご招待',
    link1: 'https://onl.sc/zwa38S3',
    link1Title: 'ご相談はこちらへ',
    link1Text: '株式会社ビジプラ公式LINE',
    link2: 'https://nakatayuto.top/G',
    link2Title: 'お申し込みはこちらへ',
    link2Text: '中田雄斗補助金のプロ公式LINE登録へ',
  },
];

export const YesNoDatas = [
  [questionDatas[1], answerDatas[6]],
  [questionDatas[2], questionDatas[3]],
  [answerDatas[0], answerDatas[1]],
  [questionDatas[4], questionDatas[5]],
  [answerDatas[2], answerDatas[3]],
  [answerDatas[2], questionDatas[6]],
  [answerDatas[4], answerDatas[5]],
];
