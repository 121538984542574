import {YesNoDatas} from 'Data/Data';
import {FC} from 'react';
import './Btn.css';

type Props = {
  id: number;
  word: string;
  viewQ: number;
  toggleQuestion: (number: number) => void;
  toggleAorQ: () => void;
  yesNoDatasIndex: number;
};

const YesBtn: FC<Props> = (props) => {
  const {id, word, viewQ, toggleQuestion, toggleAorQ, yesNoDatasIndex} = props;

  const toggle = () => {
    if (!YesNoDatas[viewQ][yesNoDatasIndex].isQuestion) {
      toggleAorQ();
    }
    toggleQuestion(id);
  };

  const btnClass = !yesNoDatasIndex ? 'yes-btn' : 'no-btn';

  return (
    <button className={`yes-no-btn ${btnClass}`} onClick={toggle} type="button">
      {word}
    </button>
  );
};

export default YesBtn;
