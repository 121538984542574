import {FC, useState} from 'react';
import {YesNoDatas, questionDatas, answerDatas} from 'Data/Data';
import Btn from 'Btn';
import Questions from 'Questions';
import Answers from 'Answers';
import './QandA.css';

const QandA: FC = () => {
  const [viewQ, setViewQ] = useState(0);

  const toggleQuestion = (number: number) => {
    setViewQ(number);
  };

  const [AorQ, setAorQ] = useState(false);

  const toggleAorQ = () => {
    setAorQ(!AorQ);
  };

  const data = {
    question: questionDatas[viewQ].question,
    yesWord: questionDatas[viewQ].yesWord,
    noWord: questionDatas[viewQ].noWord,
    description: answerDatas[viewQ].description,
    answerTitle: answerDatas[viewQ].answerTitle,
    link1: answerDatas[viewQ].link1,
    link1Title: answerDatas[viewQ].link1Title,
    link1Text: answerDatas[viewQ].link1Text,
    link2: answerDatas[viewQ].link2,
    link2Title: answerDatas[viewQ].link2Title,
    link2Text: answerDatas[viewQ].link2Text,
    yes: YesNoDatas[viewQ][0],
    no: YesNoDatas[viewQ][1],
  };

  return (
    <>
      {AorQ === false ? (
        <div className="question-content">
          <Questions question={data.question} />
          <div className="y-n-btns">
            <Btn
              id={data.yes.id}
              word={data.yesWord}
              toggleQuestion={toggleQuestion}
              toggleAorQ={toggleAorQ}
              viewQ={viewQ}
              yesNoDatasIndex={0}
            />
            <Btn
              id={data.no.id}
              word={data.noWord}
              toggleQuestion={toggleQuestion}
              toggleAorQ={toggleAorQ}
              viewQ={viewQ}
              yesNoDatasIndex={1}
            />
          </div>
        </div>
      ) : (
        <Answers
          answerTitle={data.answerTitle}
          description={data.description}
          link1={data.link1}
          link1Title={data.link1Title}
          link1Text={data.link1Text}
          link2={data.link2}
          link2Title={data.link1Title}
          link2Text={data.link2Text}
        />
      )}
    </>
  );
};
export default QandA;
